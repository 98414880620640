import { WithId } from '@/common/types'

export const CalendarResourceTypes = Object.freeze({
  EQUIPMENT: 'EQUIPMENT',
  HUMAN_RESOURCE: 'HUMAN_RESOURCE',
  PROPERTY: 'PROPERTY',
})

type ValueOf<T> = T[keyof T]
export type CalendarResourceType = ValueOf<typeof CalendarResourceTypes>

type ResourceCategory = {
  id: string
  name: string
  path: string
  set: {
    id: string
    name: string
  }
  type: 'LIST' | 'TREE'
}

export type CalendarNestedResourceElastic = WithId & {
  name?: string | null
  floorSize?: number
  internalInfo?: string
  personCapacity?: number
}

export type CalendarCompactResourceElastic = WithId & {
  name: string
  nestedResources?: CalendarNestedResourceElastic[]
}

export type CalendarResourceElastic = WithId & {
  categories: ResourceCategory[]
  floorSize?: number
  internalInfo?: string
  isPooled: boolean
  name?: string | null
  nestedResources?: CalendarNestedResourceElastic[]
  personCapacity?: number
  timezone?: string
  type: CalendarResourceType
}

type CalendarListingSearchGroup = {
  active: boolean
  categoryPaths: string[]
  id: string
  isRestrictedMode: boolean
  label: string
}

export type CalendarListingPreset = {
  groupsDisplay: 'hidden' | 'show_once' | 'repeat'
  id: string
  label: string
  resourceIds: string[]
  resourceProductDisplay: boolean
  searchGroups: CalendarListingSearchGroup[]
  sortOrder: 'resource' | 'time'
  showAllTasks: boolean
  taskDisplay: 'extended' | 'minimal'
  userGroupId: string[]
  viewAssigneeSelection: string[]
}
